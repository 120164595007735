import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import "./list.scss";
import ListItem, { ListItemData } from "./listItem";

interface Props {
  onLoadingComplete: () => void;
}

const List: React.FC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query LinkQuery {
      list: allWpLink(sort: {fields: links___order, order: DESC}) {
        nodes {
          title
          links {
            credits
            url
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000, quality: 90)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="list">
        <ul className="list__list">
          {data.list.nodes.map((item: ListItemData, key: number) => (
            <ListItem key={key} {...item} index={key} />
          ))}
        </ul>
        {/* <div className="list-footer">
          <div>
          Studio Rocani<br />
          Creative Development<br />
          </div>
          <a href="mailto:hello@rocani.net">hello@rocani.studio</a>
        </div> */}
    </div>
  );
};

export default List;
