import React from "react";
import { graphql } from "gatsby";
import List from "components/list/list";
import "./frontpage.template.scss";
import { pageContextProps } from "utils/props.utils";
import SEO from "components/seo/seo";
import { Head } from "components/head/head";


interface Props {
  pageContext: pageContextProps;
}

const FrontpageTemplate: React.FC<Props> = (props) => {
  return (
    <div className="frontpage-template">
      <SEO {...props.pageContext.seo} />
      <div className="frontpage-template__wrapper">
        {/* <div className="frontpage-template__inner">
          <p>
            We are a Berlin based creative development studio for unique
            interactive experiences. We design and develop websites,
            applications and installations for global brands and cultural
            institutions.
          </p>
        </div> */}

        <List onLoadingComplete={() => {}} />
      </div>
    </div>
  );
};

export default FrontpageTemplate;

// export { Head } from "components/head/head";
