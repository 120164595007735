import Img, { ImgProps } from "components/img/img";
import React from "react";
import "./listItem.scss";

export interface ListItemData {
  title: string;
  featuredImage: {
    node: ImgProps;
  };
  links: {
    credits: string;
    url: string;
  };
}

interface Props extends ListItemData {
  index: number;
}

const ListItem: React.FC<Props> = (props) => {
  return (
    // animation render from top to bottom
    <li className="listItem" style={{animationDelay: `${props.index * .1}s`}}>
      {!!props.featuredImage && (
        <Img {...props.featuredImage.node} className="listItem__image" />
      )}
      <div className="listItem__dark"></div>
        <div className="listItem__content">
          <h4 className="listItem__content-title">{props.title}</h4>
          {/* <span className="listItem__content-credits">
            {props.links.credits}
          </span> */}
        </div>
      
      <a className="listItem__link" target="_blank" href={props.links.url}></a>
    </li>
  );
};

export default ListItem;
